<script lang="ts">
    import type { SeoFragment } from '$gen/graphql';

    export let seo: SeoFragment;
</script>

<svelte:head>
    {#if seo}
        <!-- Primary Meta Tags -->
        <title>{seo.title}</title>
        <meta name="title" content={seo.title} />
        <meta name="description" content={seo.description} />

        <!-- Open Graph / Facebook -->
        <meta property="og:type" content="website" />
        <meta property="og:title" content={seo.social?.facebook?.title} />
        <meta property="og:description" content={seo.social?.facebook?.description} />
        {#if seo.social?.facebook?.image}
            <meta property="og:image" content={seo.social?.facebook?.image?.url} />
        {/if}

        <!-- Twitter -->
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={seo.social?.twitter?.title} />
        <meta property="twitter:description" content={seo.social?.twitter?.description} />
        {#if seo.social?.twitter?.image}
            <meta property="twitter:image" content={seo.social?.twitter?.image?.url} />
        {/if}
    {/if}
</svelte:head>
